@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@300;400;700&family=Playfair+Display:wght@700&family=Roboto:wght@100;300;400;700&display=swap);
@import url(https://use.fontawesome.com/releases/v6.4.2/css/all.css);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* Document
 * ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */

html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
 * ========================================================================== */

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */

main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * Show the overflow in Edge 18- and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */

textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* User interaction
 * ========================================================================== */

*,
*:before,
*:after {
	box-sizing: inherit;
	margin: 0;
	padding: 0;
  word-break: break-word;
}

html {
	box-sizing: border-box;

	font-size: 62.5%;
}

body {
	font-family: "Roboto", "Noto Sans HK", sans-serif;

	background-color: #fdfdfd;
}

a:link,
a:visited,
a:hover {
	text-decoration: none;
	color: inherit;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0;
}

#title {
	text-align: center;
	font-family: arial, sans-serif;
}

#event {
	text-align: center;
	font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	border: 3px solid #ddd;
	width: 100%;
}

#event td,
#event th {
	border: 1px solid #ddd;
	padding: 8px;
}

#event tr:nth-child(even) {
	background-color: #f2f2f2;
}

#event tr:hover {
	background-color: #ddd;
}

#event th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: center;
	background-color: #4caf50;
	color: white;
}

.react-datepicker__tab-loop {
	flex: 0 1 !important;
}

/* react-datepicker style workaround */
.react-datepicker {
  font-size: 1.3rem !important;
}
.react-datepicker__current-month {
  font-size: 1.5rem !important;
}
.react-datepicker__header {
  padding-top: 6px !important;
}
.react-datepicker__navigation {
  top: 13px !important;
}
.react-datepicker__day-name, .react-datepicker__day {
  margin: 0.5rem !important;
}
.dropdown-toggle::after {
  display: none;
}
.styles_toast__OW0bK {
	width: 350px;
	display: flex;
	min-height: 75px;
	position: relative;
	align-items: center;
	justify-content: center;

	opacity: 1;
	padding: 15px;
	color: white;
	cursor: pointer;
	border-radius: 5px;
	box-sizing: border-box;
	box-shadow: 0px 0px 2px rgb(69, 69, 69);

	transition: 0.2s;
	animation: styles_fadeIn__3qk9f 0.75s;
}
.styles_toast__OW0bK:hover {
	transition: 0.2s;
	transform: scale(0.95);
	box-shadow: 0px 0px 3px gray;
}
.styles_success__7BCVd {
	background-color: #83bd92;
}
.styles_warning__1Waiw {
	background-color: #bda883;
}
.styles_error__2ZOYI {
	background-color: #bd8383;
}
.styles_info__2x6Z6 {
	background-color: #8398bd;
}

.styles_message__3cKK- {
	font-size: medium;
}

@keyframes styles_fadeIn__3qk9f {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.styles_toastContainer__20GFV {
	grid-gap: 10px;
	gap: 10px;
	display: flex;
	flex-direction: column;
	z-index: -1;
}

