.toast {
	width: 350px;
	display: flex;
	min-height: 75px;
	position: relative;
	align-items: center;
	justify-content: center;

	opacity: 1;
	padding: 15px;
	color: white;
	cursor: pointer;
	border-radius: 5px;
	box-sizing: border-box;
	box-shadow: 0px 0px 2px rgb(69, 69, 69);

	transition: 0.2s;
	animation: fadeIn 0.75s;
}
.toast:hover {
	transition: 0.2s;
	transform: scale(0.95);
	box-shadow: 0px 0px 3px gray;
}
.success {
	background-color: #83bd92;
}
.warning {
	background-color: #bda883;
}
.error {
	background-color: #bd8383;
}
.info {
	background-color: #8398bd;
}

.message {
	font-size: medium;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
