/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import-normalize;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@300;400;700&family=Playfair+Display:wght@700&family=Roboto:wght@100;300;400;700&display=swap");
@import url("https://use.fontawesome.com/releases/v6.4.2/css/all.css");

*,
*:before,
*:after {
	box-sizing: inherit;
	margin: 0;
	padding: 0;
  word-break: break-word;
}

html {
	box-sizing: border-box;

	font-size: 62.5%;
}

body {
	font-family: "Roboto", "Noto Sans HK", sans-serif;

	background-color: #fdfdfd;
}

a:link,
a:visited,
a:hover {
	text-decoration: none;
	color: inherit;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0;
}

#title {
	text-align: center;
	font-family: arial, sans-serif;
}

#event {
	text-align: center;
	font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	border: 3px solid #ddd;
	width: 100%;
}

#event td,
#event th {
	border: 1px solid #ddd;
	padding: 8px;
}

#event tr:nth-child(even) {
	background-color: #f2f2f2;
}

#event tr:hover {
	background-color: #ddd;
}

#event th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: center;
	background-color: #4caf50;
	color: white;
}

.react-datepicker__tab-loop {
	flex: 0 !important;
}
